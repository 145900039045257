import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { config } from "../config";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, setUser } from "../slices/userSlice";
import { setFollow } from "../slices/followSlice";
import { setRdv } from "../slices/rdvSlice";
import { setProspect } from "../slices/prospectSlice";
import { getAllRdv } from "../api/rdv";
import { getAllFollow } from "../api/follow";
import { getAllProspect } from "../api/prospect";



//HOC de controle des data et de la sécurité
const RequireAuth = (props) => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  //je récup le params de la route demandée
  const params = useParams();

  const Child = props.child;

  // gestion des state
  const [redirect, setRedirect] = useState(false);
  
  useEffect(()=>{
      //récupération du token dans le localStorage
      const token = window.localStorage.getItem("saas-token");
      //si le token est null et que la route est protégée
      if (token === null && props.auth) {
        //on demande une redirection
        setRedirect(true);
      //sinon
      }else{
        //si l'utilisateur est déconnecté dans le store de redux
        if (user.isLogged === false) {
          //on va vérifier le token (ajax)
          axios.get(config.api_url + "/api/v1/checkToken", {headers: { "x-access-token": token }})
          .then((res) => {
            console.log("RequireAuth useEffect", res);
            //si le status de la réponse n'est pas 200
            if (res.data.status !== 200) {
              //si la route est protégée
              if (props.auth) {
                //on demande la redirection
                window.localStorage.removeItem("saas-token");
                setRedirect(true);
              } 
            //sinon (c'est 200)
            }else{
              //on récup les infos de l'utilisateur (objet) qu'on stock dans une variable user
              let user = res.data.user[0];
              //on rajoute le token à l'objet
              user.token = token;
              //on met à jour le store pour connecter l'utilisateur
              dispatch(setUser(user));
              
              //on récup les rdv de l'utilisateur (fonction ajax)
              getAllRdv(user.id)
                .then((res) => {
                  console.log("all rdv", res);
                  dispatch(setRdv(res.rdv));
                })
                .catch((err) => {
                  console.log(err);
                });
              //on récuup les prospect de l'utilisateur (fonction ajax)
              getAllProspect(user.id)
                .then((res) => {
                  console.log("all prospect", res);
                  dispatch(setProspect(res.prospects));
                })
                .catch((err) => {
                  console.log(err);
                });
              
              //on récuup les follow de l'utilisateur (fonction ajax)
              getAllFollow(user.id)
                .then((res) => {
                  console.log("all follow", res);
                  dispatch(setFollow(res.follows));
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            console.log("error checkToken", err);
          });

        }
      }        
  }, [])
  
  
  if (redirect) {
    return <Navigate to="/login" />;
  }
  //{...props} = transmet au composant enfant les props du parent (comme un relais)
  //params = j'ai crée une une props qui envoi le params de l'url (récupéré en haut par useParams) vers le composant enfant
  return <Child {...props} params={params} />;
}


export default RequireAuth;