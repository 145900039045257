import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { selectUser } from "../slices/userSlice";

const Header = (props) => {
  const user = useSelector(selectUser);

  return (
    user.isLogged ? (
      <ul className="trl-header">
        <div>
          <Link to="/">Accueil</Link>
          <Link to="/agenda">Agenda</Link>
          <Link to="/prospect/all">Suivi</Link>
          <Link to="/stats">Statistiques</Link>
          <Link to="/logout">
            <FontAwesomeIcon icon={faSignOutAlt} />
          </Link>
        </div>
      </ul>
    ) : null
  );
  
};

export default Header;

