import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser } from "../slices/userSlice";
import { setProspect, selectProspect } from "../slices/prospectSlice";
import { getAllProspect } from "../api/prospect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";

const Prospect = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const prospects = useSelector(selectProspect);

  // Charger les prospects lorsque l'utilisateur est défini
  useEffect(() => {
    if (user && user.infos && user.infos.id) {
      getAllProspect(user.infos.id)
        .then((res) => {
          if (res && res.status === 200 && Array.isArray(res.prospects)) {
            dispatch(setProspect(res.prospects));
          } else {
            console.error("Données invalides reçues :", res);
          }
        })
        .catch((err) => {
          console.error("Erreur lors de la récupération des prospects :", err);
        });
    }
  }, [user, dispatch]);

  return (
    <div>
      <Link to="/prospect/add" className="addProspect">
        <FontAwesomeIcon icon={faPlus} /> Ajoutez un nouveau prospect
      </Link>
      {prospects.length > 0 ? (
        <>
          <h2 className="follow">Suivi clients</h2>
          <ul className="prospect-list">
            {prospects.map((prospect) => (
              <li key={prospect.id}>
                <Link to={`/prospect/${prospect.id}`}>
                  {prospect.firstName} {prospect.lastName}
                </Link>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <p className="noProspects">Aucun prospect enregistré</p>
      )}
    </div>
  );
}

export default Prospect;
