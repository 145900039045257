// Importations nécessaires
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setRdv, selectRdv } from '../slices/rdvSlice';
import { selectUser } from '../slices/userSlice';
import { saveRdv, deleteRdv, updateRdv, getAllRdv } from '../api/rdv';
import moment from 'moment';

// Définition des couleurs pour les niveaux d'importance
let colors = {
  "color-1": "rgba(102, 195, 131 , 1)",
  "color-2": "rgba(242, 177, 52, 1)",
  "color-3": "rgba(235, 85, 59, 1)",
};

const Agenda = () => {
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    // États locaux pour gérer les événements et les modals
    const [events, setEvents] = useState([]); // Liste des rendez-vous
    const [selectedEvent, setSelectedEvent] = useState(null); // Rendez-vous sélectionné
    const [showModal, setShowModal] = useState(false); // Affichage du modal
    const [newEvent, setNewEvent] = useState({}); // Nouveau rendez-vous

    // Utilisation de useEffect pour récupérer les rendez-vous de l'utilisateur lors du montage du composant
    useEffect(() => {
        getAllRdv(user.infos.id)
          .then((res) => {
            console.log("Réponse complète de l'API :", res); // Log de la réponse complète
            if (res && res.status === 200 && Array.isArray(res.rdv)) {
              // Transformation des données reçues pour les adapter au format FullCalendar
              const rdv = res.rdv.map((item) => ({
                  ...item,
                  id: item._id,
                  title: item.name,
                  start: new Date(item.startDateTime),
                  end: new Date(item.endDateTime),
                  backgroundColor: colors[item.classes]
              }));
              console.log("Événements traités :", rdv); // Log des événements traités
              setEvents(rdv);
            } else {
              console.error("Données invalides reçues :", res); // Log des erreurs de données
            }
          })
          .catch((err) => {
            console.error("Erreur lors de la récupération des rendez-vous :", err); // Log des erreurs de récupération
          });
    }, [user.infos.id]);

    // Gestion de la sélection de date pour ajouter un nouveau rendez-vous
    const handleDateSelect = (selectInfo) => {
        setNewEvent({
            start: selectInfo.start,
            end: selectInfo.end,
            classes: 'color-1' // Niveau d'importance par défaut
        });
        setShowModal(true);
    };

    // Gestion du clic sur un événement existant pour le modifier
    const handleEventClick = (clickInfo) => {
        console.log("Événement cliqué :", clickInfo); // Log de l'événement cliqué
        const event = events.find(ev => ev.id === clickInfo.event.id);
        if (event) {
            setSelectedEvent(event);
            setNewEvent({
                id: event._id,
                title: event.name,
                start: event.start,
                end: event.end,
                classes: event.classes
            });
            setShowModal(true);
        } else {
            console.error("Événement non trouvé !"); // Log des erreurs de recherche d'événement
        }
    };

    // Gestion de la suppression d'un événement
    const handleEventRemove = async (event) => {
        try {
            console.log("Tentative de suppression de l'événement avec ID :", event.id);
            const response = await deleteRdv(event.id);

            if (response.status === 200) {
                // Met à jour l'état local pour retirer l'événement supprimé
                setEvents((prevEvents) => prevEvents.filter(ev => ev.id !== event.id));
                setShowModal(false); // Ferme le modal après suppression
            } else {
                console.error("Erreur lors de la suppression de l'événement :", response);
            }
        } catch (error) {
            console.error("Erreur lors de la suppression de l'événement :", error);
        }
    };

    // Sauvegarde du rendez-vous (ajout ou mise à jour)
    const saveEvent = () => {
        const eventData = {
            name: newEvent.title,
            startDateTime: moment(newEvent.start).format("YYYY-MM-DD HH:mm:ss"),
            endDateTime: moment(newEvent.end).format("YYYY-MM-DD HH:mm:ss"),
            classes: newEvent.classes,
            user_id: user.infos.id,
            _id: newEvent.id || Math.random().toString(36).substr(2, 9) // Génère un ID aléatoire si non présent
        };

        if (newEvent.id) {
            // Mise à jour d'un rendez-vous existant
            updateRdv(eventData, newEvent.id).then((response) => {
                if (response.status === 200) {
                    setEvents(events.map(ev => ev.id === newEvent.id ? { ...newEvent, id: newEvent.id, title: newEvent.title, backgroundColor: colors[newEvent.classes] } : ev));
                }
            });
        } else {
            // Ajout d'un nouveau rendez-vous
            saveRdv(eventData).then((response) => {
                if (response.status === 200) {
                    setEvents([...events, { ...eventData, id: eventData._id, title: eventData.name, start: new Date(eventData.startDateTime), end: new Date(eventData.endDateTime), backgroundColor: colors[eventData.classes] }]);
                }
            });
        }
        setShowModal(false);
    };

    return (
        <div>
            <h2>Page agenda</h2>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                locale="fr"
                events={events}
                selectable
                select={handleDateSelect}
                eventClick={handleEventClick}
                eventRemove={handleEventRemove}
            />
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedEvent ? "Modifier Rendez-vous" : "Ajouter Rendez-vous"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="eventTitle">
                            <Form.Label>Titre</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={newEvent.title || ''} 
                                onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })} 
                            />
                        </Form.Group>
                        <Form.Group controlId="eventStart">
                            <Form.Label>Début</Form.Label>
                            <Form.Control 
                                type="datetime-local" 
                                value={moment(newEvent.start).format("YYYY-MM-DDTHH:mm")} 
                                onChange={(e) => setNewEvent({ ...newEvent, start: new Date(e.target.value) })} 
                            />
                        </Form.Group>
                        <Form.Group controlId="eventEnd">
                            <Form.Label>Fin</Form.Label>
                            <Form.Control 
                                type="datetime-local" 
                                value={moment(newEvent.end).format("YYYY-MM-DDTHH:mm")} 
                                onChange={(e) => setNewEvent({ ...newEvent, end: new Date(e.target.value) })} 
                            />
                        </Form.Group>
                        <Form.Group controlId="eventClass">
                            <Form.Label>Importance</Form.Label>
                            <div>
                                {Object.keys(colors).map((key) => (
                                    <span 
                                        key={key}
                                        onClick={() => setNewEvent({ ...newEvent, classes: key })}
                                        style={{
                                            display: 'inline-block',
                                            width: '20px',
                                            height: '20px',
                                            borderRadius: '50%',
                                            backgroundColor: colors[key],
                                            margin: '0 5px',
                                            cursor: 'pointer',
                                            border: newEvent.classes === key ? '2px solid black' : 'none'
                                        }}
                                    ></span>
                                ))}
                            </div>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Annuler</Button>
                    <Button variant="primary" onClick={saveEvent}>Enregistrer</Button>
                    {selectedEvent && (
                        <Button variant="danger" onClick={() => handleEventRemove(selectedEvent)}>Supprimer</Button>
                    )}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Agenda;
